export const pinnedPosts = {
  'post-Qnnbjo6M4': 1, // Unexplained Wealth Orders
}

export const orderedCategories = {
  INJUNCTIONS: 1,
  CONTEMPT: 2,
  CASES: 3,
  MORE: 4,
}

export const SEOTitles = {
  index: {
    title: 'Home',
    description: '',
  },
  aboutUs: {
    title: 'About Us',
    description: '',
  },
  contactUs: {
    title: 'Contact Us',
    description:
      'Want To Get In Touch? Feel free to contact us using our contact form below or message us directly at info@civilfraud.co.uk...',
  },
  thankYou: {
    title: 'Thank You',
    description: '',
  },
  gallery: {
    title: 'Our Gallery',
    description:
      'Welcome to our Gallery || See our latest images shared with you on our Instagram account',
  },
  quiz: {
    title: 'Our Quiz',
    description:
      'Welcome to the CivilFraud Quiz || Our quiz has a database of over 70 questions, make sure to stop by and test your knowledge!',
  },
  quizResults: {
    title: 'Your Results',
    description:
      'Welcome to the CivilFraud Quiz || Our quiz has a database of over 70 questions, make sure to stop by and test your knowledge!',
  },
  legal: {
    title: 'Legal',
    description:
      'Welcome to the CivilFraud.co.uk || Find out about the TOA and other information behind CivilFraud.co.uk',
  },
  notFound: {
    title: 'Not found',
    description:
      'Woops, something went wrong. This page does not exist or is no longer reachable. You seem to have hit a dead end.',
  },
}
